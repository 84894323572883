import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/content/writing-for-components/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Frequently used messages - this page is in development (ETA Q1 2021)`}</h2>
        <h3>{`Notification - error`}</h3>
        <p>{`Tell the user briefly what has happened, why (if relevant), and given them the option to fix it with an action or link.`}</p>
        <ul>
          <li parentName="ul">{`What. Why. How to fix it.`}</li>
          <li parentName="ul">{`Unable to connect the call. Check your internet connection and `}{`[try again]`}{`.`}</li>
        </ul>
        <h3>{`Notification - confirmation`}</h3>
        <p>{`Echo the wording the user has just seen when taking the action. Where possible, be specific.`}</p>
        <ul>
          <li parentName="ul">{`5 users added to Team Twilio`}</li>
          <li parentName="ul">{`Message sent`}</li>
        </ul>
        <p>{`Suggest an addition `}{`—`}{` select "Ask a question" on this page to start a discussion.`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      